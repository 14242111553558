import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default ({
    children,
    to,
    dangerouslySetInnerHTML,
    className,
    onClick
}) => {
    const isExternal = to.startsWith('http')

    const commonProps = {
        className,
        onClick,
    }

    if(isExternal){
        return (
            <a 
                href={to}
                {...commonProps}
                target="_blank"
            >
                { children }
            </a>
        )
    }

    const handleClick = e => {
        if(onClick){
            onClick(e)
        }

        if(typeof window !== 'undefined'){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    return (
        <AniLink 
            fade
            preventScrollJump
            duration={0.7}
            to={to.startsWith('/') ? to : '/'+to} 
            dangerouslySetInnerHTML={dangerouslySetInnerHTML} 
            className={className}
            onClick={handleClick}
        >
            { children }
        </AniLink>
    )
}