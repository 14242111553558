import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'

import { isActiveLink, useIsMobile } from 'utils'
import FadeCollapse from 'components/fadeCollapse'

export default ({
    location,
    handleOpenParent,
    hoveredMenuItemIndex,
    onClick
}) => {
    const data = useStaticQuery(graphql`
        query menu {
            siteFields {
                menu(id: "TWVudTo0") {
                    id
                    name
                    menuItems {
                        nodes {
                            label 
                            url
							childItems {
								nodes {
									label
									title
									url	
								}
							}
                        }
                    }
                }
            }
        }
    `)

    const isMobile = useIsMobile()

    const { nodes: menuItems } = data.siteFields.menu.menuItems

    return (
        <ul
            className="menu"
        >
            {menuItems.map(({
                url,
                label,
                childItems: {
                    nodes: childItems
                }
            }, index) => {
                const isSelected = isActiveLink(location, url)

                let hasActiveChild = false

                childItems.forEach(childItem => {
                    if (isActiveLink(location, childItem.url)) {
                        hasActiveChild = true
                    }
                })

                const isActive = (hoveredMenuItemIndex === index || hasActiveChild || isSelected)

                return (
                    <li
                        className={isActive ? 'selected' : ''}
                        key={index}
                        onMouseEnter={() => {
                            if (!isMobile) {
                                handleOpenParent(index, childItems)
                            }
                        }}
                    >
                        {childItems.length ?
                            <div
                                onClick={() => {
                                    if (isMobile) {
                                        handleOpenParent(index, childItems)
                                    }
                                }}
                            >
                                {label}
                                <i className="fe fe-chevron-down" />
                                <FadeCollapse
                                    open={isMobile && isActive}
                                >
                                    <ul>
                                        {childItems.map((item, index) => {
                                            const {
                                                label,
                                                url
                                            } = item

                                            return (
                                                <li className={isActiveLink(location, url) ? 'active' : ''} key={index}>
                                                    <Link to={url}>
                                                        {label}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </FadeCollapse>
                            </div>
                            :
                            <Link
                                to={url}
                                onClick={onClick || undefined}
                            >
                                {label}
                            </Link>
                        }
                    </li>
                )
            })}
        </ul>
    )
}
