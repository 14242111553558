import gql from 'graphql-tag'

export const GET_POSTS = gql`
    query GET_POSTS {
        posts {
            edges {
                node {
                    link
                    title
                    excerpt
                    date
                    featuredImage {
                        mediaItemUrl
                    }
                    categories {
                        nodes {
                        name
                        slug
                    }
                }
                tags {
                    nodes {
                        name
                        slug
                        }
                    }
                }
            }
        }
    }
`

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
    ) {
        submitForm (
            input: {
                data: $data
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const GET_FORM = gql`
    query GET_FORM(
        $id: ID!
    ) {
        form (
            id: $id
        ) {
            formFields {
                sections {
                    title
                    rowsHeading
                    rows {
                        columns {
                            label
                            options
                            type    
                        }
                    }
                }
            }
        }
    }
`

export const GET_PROPERTIES_BY_IDS = gql`
    query GET_PROPERTY_BY_ID (
        $ids: [ID!]!
    ) {
        listings(ids: $ids) {
            id
            address
            dataJSON
        }
    }
`