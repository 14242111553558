import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { maybeTriggerRecompile } from 'utils'

export const MainContext = React.createContext()

const Provider = props => {
	const [modalData, setModalData] = useState(false)
	const [activeForm, setActiveForm] = useState('Zm9ybTo0NDY=')
    const [formVisible, setFormVisible] = useState(true)
    const [isSubmitted, setSubmitted] = useState(false)
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)
	const invertedHeaderSections = useRef([])
	const isMobileRef = useRef(false)

	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const _window = typeof window !== 'undefined' ? window : null

    const openModal = _props => {
        setModalData({
            ..._props,
            isVisible: true
        })
	} 
	
	const closeModal = () => {
        setModalData({
            ...modalData,
            isVisible: false
        })
	}

	const setContactForm = ( formId ) => {
		setFormVisible(false)
        setActiveForm(formId)

        setTimeout(() => {
            setActiveForm(formId)
            setFormVisible(true)
        },300)
        setSubmitted(false)
	}

	const addInvertedHeaderSection = ref => {
		const { current } = invertedHeaderSections

		if(current.indexOf(ref) == -1){
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}

	const activateMobileFilter = active => {
		const body = document.body
		if ( active === true ) {
			body.classList.add('mobilefilteractive')
		} else {
			body.classList.remove('mobilefilteractive')
		}
	}

	useEffect(() => {
		let scrollHandler = null

		maybeTriggerRecompile()

		if(_window){
			scrollHandler = _window.addEventListener('scroll', checkInvertedHeaderOffsets)

			isMobileRef.current = _window.innerWidth <= 500 

			_window.addEventListener('resize', () => {
				const isNowMobile = _window.innerWidth <= 500

				if(isMobileRef.current !== isNowMobile){
					window.location.reload()
				}
			})
		}

		return () => {
			if(scrollHandler){
				scrollHandler.remove()
			}
		}
	}, [_window])

	const checkInvertedHeaderOffsets = () => {
		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		if(_window){
			const scrollOffset = _window.pageYOffset

			if(scrollOffset > 10 && !hasScrolledRef.current){
				setHasScrolled(true)
			}else if(scrollOffset <= 10 && hasScrolledRef.current){
				setHasScrolled(false)
			}
		}

		invertRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if(isOverSection){
				_isHeaderInverted = true
			}
		})

		if(_isHeaderInverted !== isHeaderInvertedRef.current){
			setIsHeaderInverted(_isHeaderInverted)
		}
	}

	const resetHeader = () => {
		invertedHeaderSections.current = []

		checkInvertedHeaderOffsets()
	}

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteFields {
				options {
					options {
						logos {
							standard {
								sourceUrl
							}
							inverted {
								sourceUrl
							}
							wordmark {
								sourceUrl
							}
						}
						phoneNumber
						emailAddress
						address
						socialLinks {
							type
							url
						}
					}
				}
			}
		}
	`)
	
	const { options } = optionsQuery.siteFields.options

	return (
		<MainContext.Provider 
			value={{
				options,
				openModal,
				closeModal,
				modalData,
				isHeaderInverted,
				addInvertedHeaderSection,
				hasScrolled,
				checkInvertedHeaderOffsets,
				resetHeader,
				activateMobileFilter,
				setContactForm,
				activeForm,
				formVisible,
				setFormVisible,
				isSubmitted,
				setSubmitted
			}}
		>
			{props.children}
		</MainContext.Provider>
	)
};

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
);