import React from "react"

import TextInput from "components/textInput"
import EmailInput from "components/emailInput"
import TextArea from "components/textArea"
import Checkboxes from 'components/checkboxes'
import Radiobuttons from 'components/radiobuttons'
import Select from 'react-select'

  
export default ({column, index, handleChange}) => {
    let Component = null 

    switch(column.type) {
        case 'text':
            Component = TextInput
            break;
        case 'email':
            Component = EmailInput
            break;
        case 'select':
            Component = Select
            break;
        case 'textarea':
            Component = TextArea
            break
        case 'checkboxes':
            Component = Checkboxes
            break
        case 'radioboxes':
            Component = Radiobuttons
            break;
        }

        const inputKey = column.label
    return (
        <div>
            <Component label={column.label} options={column.options} inputKey={inputKey} handleChange={handleChange}/>
        </div>
    )
}