import React from "react"

export default ({column, inputKey, handleChange}) => {
    return (
        <input
            type="text"
            placeholder={inputKey}
            onChange={e => handleChange(inputKey, e.target.value)}
        />
    )
}