import React, { useState, useEffect } from "react"

import FormTab from "components/formTab"
import { cloneDeep } from 'lodash'
import { emailIsValid } from 'utils'

export default ({ 
    handleSubmit,
    form
}) => {
    const [activeTab, setActiveTab] = useState(0)
    const [fieldValues, setFieldValues] = useState({})

    const activeSection = form.formFields.sections[activeTab]

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)
        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const visibleRows = activeSection.rows

    const _isValid = () => {
        let hasEmptyColumn = false

        visibleRows.forEach(row => {
            row.columns.forEach(column => {
                const value = fieldValues[column.label]

                if (column.required) {
                    if(!value || value === ''){
                        hasEmptyColumn = true
                    }else if(column.type === 'email'){
                        if(!emailIsValid(value)){
                            hasEmptyColumn = true
                        }
                    }
                }
            })
        })

        return !hasEmptyColumn
    }

    const isValid = _isValid()

    return (
        <>
            { activeSection ?
                <form 
                    className="form-container" 
                    id="form-container" 
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(fieldValues)
                    }}
                >
                    <div className="form-tabs">
                        <div className={"tab"}>
                            <FormTab 
                                section={activeSection} 
                                handleChange={handleChange}
                            />
                        </div>
                        <button 
                            disabled={!isValid}
                            type="submit" 
                            className='button outline'
                        >
                            Submit
                        </button>
                    </div>
                </form>
            : null 
            }
        </>
    )
}
