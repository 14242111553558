import React from "react"

export default ({column}) => {
    return (
        <>
        </>
        /*
        <label className="radio-container">
            <input type="radio"/>
            <span className="radio"></span>
        </label>*/
    )
}