import React from "react"

export default ({column, inputKey, handleChange, label}) => {
    // console.log('input key inside text area')
    // console.log(inputKey)

    // console.log('handle change inside text area')
    // console.log(handleChange)
    
    return (
        <textarea 
            onChange={e => handleChange(inputKey, e.target.value)}
            placeholder={label}
        />
    )
}