import React from "react"
import FormColumn from "./formColumn"

export default ({section, handleChange}) => {
    return (
        <div>
            <h2 className="h1">
                {section.rowsHeading}
            </h2>
            {section.copy &&
                <p>
                    {section.copy}
                </p>    
            }
            <ul>
                { section.rows.map((row, index) => {
                    return (
                        <li className="row" key={index}>
                            <p>
                                {row.heading}
                            </p>
                            <ul className={`columns columns-${row.columns.length}`}>
                                {row.columns.map((column, columnIndex) => {
                                    return (
                                        <li key={columnIndex}>
                                            <FormColumn column={column} index={columnIndex} handleChange={handleChange}/>
                                        </li>
                                    )
                                })}
                            </ul>   
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
