import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import Link from 'components/link'

import { nl2br } from 'utils'

import moment from 'moment'

export default () => {
    const data = useStaticQuery(graphql`
        query footerMenu {
            siteFields {
                menu(id: "TWVudToxMg==") {
                    id
                    name
                    menuItems {
                        nodes {
                            label 
                            url
                        }
                    }
                }
            }
        }
    `)

    const { nodes: menuItems } = data.siteFields.menu.menuItems

    const {
        options
    } = useContext(MainContext)

    const {
        phoneNumber,
        emailAddress,
        address,
        socialLinks
    } = options

    return (
        <>
            <footer>
                <div className="grid">
                    <div className="container">
                        <div className="logo column">
                            <img className="logo" src={options.logos.standard.sourceUrl}/>
                        </div>
                        <div className="phone column">
                            <span>
                                Contact
                            </span>
                            <ul>
                                <li>
                                    <span>
                                        PH:
                                    </span>
                                    <p>
                                        <a href={`tel:${ phoneNumber }`}>
                                            { phoneNumber }
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <span>
                                        E:
                                    </span>
                                    <p>
                                        <a href={`mailto:${ emailAddress }`}>
                                            { emailAddress }
                                        </a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="address column">
                            <span>
                                Address
                            </span>
                            <ul>
                                <li>
                                    <p dangerouslySetInnerHTML={{ __html: nl2br(address) }} />
                                </li>
                            </ul>
                        </div>
                        <div className="social column">
                            <span>
                                Follow
                            </span>
                            <ul>
                                { socialLinks.map(({
                                    type,
                                    url
                                }) => (
                                    <li key={type}>
                                        <a href={url} target="_blank">
                                            <i className={`fa fa-${ type }`} />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="portal column">
                            <span>
                                <a href="https://client.propertytree.com/invitation?invitation=z3YQI5EWlUe7VttN1IH-KA" target="_blank" rel="noopener noreferrer">Property portal login</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="bottom-bar">
                    <div className="container">
                        <span>
                            &copy; Copyright { moment().format('YYYY') } William Huxley
                        </span>
                        { menuItems &&
                            <ul>
                                { menuItems.map((item, index) => {
                                    const {
                                        label,
                                        url
                                    } = item

                                    return (
                                        <li key={index}>
                                            <Link to={url}  dangerouslySetInnerHTML={{ __html: label }} />
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </div>
                </div>
            </footer>
        </>
    )
}
