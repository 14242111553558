import React from "react"
import { Link } from "gatsby"

export default ({options, handleChange, selectedValues}) => {
    const lines = options.split("\n")

    return (
        <div className={'checkboxes'}>
            {lines.map((term, index) => {
                return (
                    /*
                    <div className="checkbox" key={index}>
                        <label>
                            <span className="checkbox-container">
                                <input type="checkbox" checked={selectedValues.indexOf(term) != -1 ? true : false}/>
                                <span className="checkmark" onClick={() => {handleChange(term)}}></span>
                            </span>
                            {term}
                        </label>
                    </div>
                    */
                   <>
                   </>
                )
            })}
        </div>
    )
}