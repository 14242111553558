import React, { useContext, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "context/context"
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import Form from 'components/form'

export default ({

}) => {
    const {
        modalData,
        closeModal
    } = useContext(MainContext)
    
    const contentRef1 = useRef(null)
    const contentRef2 = useRef(null)

    const {
        type,
        isVisible
    } = modalData

    //GENERAL STYLING
    let className = 'modal-container '
    let modalClassName = 'modal visible'

    if(modalData) {
        className += type

        if(isVisible) {
            className += ' visible'
            
            setTimeout(()=> {
                modalClassName += ' visible'
            },300)

        }
    }

    const [submitForm] = useMutation(SUBMIT_FORM)

    const handleSubmit = data => {
        const json = JSON.stringify(data)

        submitForm({
            variables: {
                data: json,
            },
        }).then((result) => {
            const success = result.data.submitForm.success

            if(success) {
                closeModal()
            }

        }).catch((error) => {
            //console.log(error)
        })
    }

    const photos = modalData.data

    const formFields = useStaticQuery(graphql`
        query {
            siteFields {
                forms {
                    nodes {
                        id
                        title
                        formFields {
                            sections {
                                title
                                rowsHeading
                                rows {
                                    columns {
                                        label
                                        options
                                        type
                                        required
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    let forms = []
    
    if ( type === 'joinOurPrivateMarket' ) {
        let joinOurPrivateMarket = formFields.siteFields.forms.nodes
        joinOurPrivateMarket = joinOurPrivateMarket.filter(function(item) {
            return item.id === 'Zm9ybTo4NDg='
        })
        forms = joinOurPrivateMarket[0]

    }

    useEffect(() => {
        if(contentRef1.current){
            contentRef1.current.on('change', index => {
                if(contentRef2.current){
                    contentRef2.current.select( index )
                }
            })
        }
        if(contentRef2.current){
            contentRef2.current.on('change', index => {
                if(contentRef1.current){
                    contentRef1.current.select( index )
                }
            })
        }
    })
    

    return (
        <div className={className} onClick={_ => closeModal()} key="4">
            <div className={modalClassName} onClick={e => {e.stopPropagation()}}>
                <div className="close-button-row">
                    <a onClick={_ => closeModal()}>
                        Close
                    </a>
                </div>
                { type === 'joinOurPrivateMarket' ?
                    <>
                        <h1>Join the Private Market</h1>
                        <p>We take privacy and security very seriously. When a new listing becomes available, our proprietary technology can catalogue the property and push it out to viable buyers within our network who have opted in to receive such information. For Private Market listings, clients can expect discreet, one-to-one service that meets their privacy needs.</p>
                        <Form 
                            handleSubmit={handleSubmit}
                            form={forms}
                        />
                    </>
                :
                    <div className="modalSlideshow">
                        <div className="modalSlideshow-cont">
                            { photos ?
                                <Flickity
                                    className='carousel slideshow2'
                                    options={{
                                        wrapAround: true,
                                        prevNextButtons: true,
                                        groupCells: 1,
                                        cellAlign: 'center',
                                        pageDots: false,
                                        adaptiveHeight: true,
                                        draggable: true,
                                    }}
                                    flickityRef={flickityRef => contentRef1.current = flickityRef}
                                    disableImagesLoaded={false}
                                >
                                    {photos && photos.map((row, index) => {
                                        const {
                                            url
                                        } = row[1].versions.large

                                        return (
                                            <div className="carousel-cell" key={index} style={{backgroundImage: "url("+url+")"}} />
                                        )
                                    })}
                                </Flickity>
                            : '' }
                            <Flickity
                                className='carousel slideshow1'
                                options={{
                                    wrapAround: true,
                                    prevNextButtons: false,
                                    groupCells: 1,
                                    cellAlign: 'center',
                                    pageDots: true,
                                    adaptiveHeight: true,
                                }}
                                flickityRef={flickityRef => contentRef2.current = flickityRef}
                                disableImagesLoaded={false}
                            >
                                {photos && photos.map((row, index) => {
                                    const {
                                        url
                                    } = row[1].versions.large

                                    return (
                                        <div className="carousel-cell" key={index}>
                                            <div style={{backgroundImage: "url("+url+")"}} />
                                        </div>
                                    )
                                })}
                            </Flickity>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
