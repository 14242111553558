import { useEffect, useState } from "react"
import axios from 'axios'
import postcodes from './postcodes.json'
import slugify from 'slugify'

const isDev = typeof window !== 'undefined' && window.location.href.indexOf('localhost') !== -1

const wpUrl = isDev ?  'http://william-huxley.test' : 'https://william-huxley.yourcreative.com.au'

export const ajaxUrl = `${ wpUrl }/wp-admin/admin-ajax.php`

export const doPropertySearch = async (
    type, 
    region, 
    beds, 
    baths, 
    propertyType, 
    priceRange,
    includeSurrounds
) => {
    const formData = new FormData()
    formData.append('action', 'query_zoo')

    if(type){
        formData.append('type', type) // 'sale' or 'lease'
    }

    if(region){
        formData.append('region', region)
    }

    if(beds){
        formData.append('beds', beds)
    }

    if(baths){
        formData.append('baths', baths)
    }

    if(propertyType){
        formData.append('propertyType', propertyType.toLowerCase())
    }

    if(priceRange) {
        formData.append('priceRange', priceRange)
    }
    
    if(includeSurrounds){
        formData.append('includeSurrounds', includeSurrounds)
    }

    const promise = await axios.post(ajaxUrl, formData)

    return promise
}

export const maybeTriggerRecompile = () => {
    const formData = new FormData()
    formData.append('action', 'force_recompile')

    axios.post(ajaxUrl, formData)
}

export const getLocalitySuggestions = query => {
    if(!query){
        return []
    }

    const matchingRegions = []

    const formattedQuery = query.toLowerCase()

    postcodes.forEach(region => {
        if(!region.locality){
            return
        }

        const suburbName = region.locality.toLowerCase()

        if(suburbName.indexOf(formattedQuery) !== -1){
            matchingRegions.push(region)
        }
    })

    return matchingRegions
}

export const ucFirst = value => {
    return typeof value === 'string' ? value.charAt(0).toUpperCase() + value.slice(1) : ''
}

export const capitaliseString = value => {
    const _value = value.toLowerCase()

    return _value.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export const nl2br = value => {
    if (typeof value === 'undefined' || !value) {
        return '';
    }

    const breakTag = '<br />'

    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const isActiveLink = (location, url) => {
    const { pathname } = location

    const _url = url.replace('/','')
    const _pathname = pathname.replace('/','')
    const isActive = _pathname && _pathname.indexOf(_url) !== -1

    return isActive
}

export const getUrlParam = name => {
    if(typeof window === 'undefined'){
        return null
    }

    const url = window.location.href

    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
    const regexS = "[\\?&]"+name+"=([^&#]*)"
    const regex = new RegExp( regexS )
    const results = regex.exec( url )

    return results == null ? null : results[1]
}

export const getParsedQuery = () => {
    const _searchData = getUrlParam('searchData')

    return _searchData ? JSON.parse(decodeURIComponent(_searchData)) : null
}

export const useIsMobile = () => {
    const [_isMobile, setIsMobile] = useState(false)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        setTimeout(() => {
            if(_window){
                const smallerWidth = _window.innerWidth < _window.screen.width ? _window.innerWidth : _window.screen.width

                setIsMobile(smallerWidth <= 500)
            }
        },1)
    },[_window])

    return _isMobile
}

export const emailIsValid = email => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase())
}

export const makePropertyUrl = listing => {
    const {
        address,
        xml_id
    } = listing

    let _address = address.street_number.replace('/',' ')

    _address = _address+' '+address.street_name+', '+address.suburb

    const url = `${ slugify(_address, {
        replacement: '-',
        lower: true
    }) }-${ xml_id }`.toLowerCase()

    return `/properties/${url}`
}

export const format24HrTime = time => {
    const timeString = time

    const hourEnd = timeString.indexOf(":")
    const H = +timeString.substr(0, hourEnd)
    const h = H % 12 || 12
    const ampm = (H < 12 || H === 24) ? "am" : "pm"
    return h + timeString.substr(hourEnd, 3) + ampm
}