import React from "react"
import SmoothCollapse from 'react-smooth-collapse'

export default ({ 
    open, 
    children,
    className = ''
}) => {
    return (
        <div className={`fade-collapse ${ open ? 'visible' : ''} ${ className }`}>
            <SmoothCollapse expanded={open}>
                { children }
            </SmoothCollapse>
        </div>
    )
}