// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-buy-rent-js": () => import("./../../../src/templates/buyRent.js" /* webpackChunkName: "component---src-templates-buy-rent-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-js": () => import("./../../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-templates-single-listing-js": () => import("./../../../src/templates/singleListing.js" /* webpackChunkName: "component---src-templates-single-listing-js" */)
}

