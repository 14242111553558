import React, { useContext, useEffect, useState, useRef } from "react"

import { MainContext } from "context/context"
import Menu from 'components/menu'
import Link from 'components/link'

import { isActiveLink } from 'utils'
import { useIsMobile } from 'utils'

export default ({
	location
}) => {
	const {
		options: {
			logos
		},
		hasScrolled,
		checkInvertedHeaderOffsets,
		resetHeader,
		isHeaderInverted,
        setContactForm,
	} = useContext(MainContext)

	const [selectedChildItems, setSelectedChildItems] = useState([])
    const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [showLoader, setShowLoader] = useState(true)

	const hoveredMenuItemIndexRef = useRef(hoveredMenuItemIndex)
	hoveredMenuItemIndexRef.current = hoveredMenuItemIndex

	useEffect(() => {
		resetHeader()

		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 10)
	}, [location])

	const isMobile = useIsMobile()

	useEffect(() => {
		setTimeout(() => {
			setMobileMenuOpen(false)
		},10)

		setTimeout(() => {
			setShowLoader(false)
		}, 1700)
	}, [location])
	    
	const handleHover = (index, items = []) => {
		if(items.length){
			const isAlreadySelected = hoveredMenuItemIndexRef.current === index

			setSelectedChildItems(items)
			setHoveredMenuItemIndex((isMobile && isAlreadySelected) ? null : index)
		}else{
			setHoveredMenuItemIndex(-1)
		}
	}

	const setContactFormToAppraisal = () => {
		setContactForm('Zm9ybTo2MDM=')
	}

	const setContactFormToContact = () => {
		setContactForm('Zm9ybTo0NDY=')
	}

	const toggleMobileMenu = () => {
		if(mobileMenuOpen){
			setMobileMenuOpen(false)
		}else{
			setMobileMenuOpen(true)
		}
	}

	return (
		<>
			<div className={ showLoader ? 'loader' : 'loader hidden' } key="1">
				<div className="loader-logo">
					<img className="logo" src={logos.standard.sourceUrl} />
				</div>
			</div>
			{ isMobile ?
				<div className={`menu-cont${ mobileMenuOpen ? ' open' : ''}`} key="2">
					<Menu
						location={location}
						handleOpenParent={handleHover}
						hoveredMenuItemIndex={hoveredMenuItemIndex}
					/>
					<Link to="/contact?type=appraisal">
						Request an appraisal
						<i className="fe fe-arrow-right" />
					</Link>
				</div>
			 : '' }
			<div
				onMouseLeave={() => handleHover(-1)}
				key="3"
			>
				<header className={`header ${ hasScrolled ? 'scrolled' : ''} ${ isHeaderInverted && !mobileMenuOpen ? 'inverted' : ''}`}>
					<div className="container">
						{ !isMobile ? <Menu 
							location={location}
							handleOpenParent={handleHover}
							hoveredMenuItemIndex={hoveredMenuItemIndex}
						/> : 
							<div className={mobileMenuOpen ? 'menu-button open' : 'menu-button'} onClick={toggleMobileMenu}>
								<span className="fe fe-menu"></span>
								<span className="fe fe-x"></span>
							</div>
						}
						<Link to={'/'} className="logo">
							<img src={logos.wordmark.sourceUrl}/>
						</Link>
						<div className="right-column">
							{ location.pathname.includes('/contact') ?
								<>
									<button onClick={ setContactFormToContact }>
										<span className="fe fe-mail"></span>
										Contact
									</button>
									<button className="button" onClick={ setContactFormToAppraisal }>
										Request Appraisal
									</button>
								</>
							:
								<>
									<Link to="/contact">
										<span className="fe fe-mail"></span>
										Contact
									</Link>
									<Link className="button" to="/contact?type=appraisal">
										Request Appraisal
									</Link>
								</>
							}
						</div>
					</div>
				</header>
				<div className={`header-sub-menu ${ hoveredMenuItemIndex !== -1 ? 'visible' : ''}`}>
					<div className="header-sub-menu-inner">
						<ul className={'container'}>
							{ selectedChildItems.map((item, index) => {
								const {
									label,
									url
								} = item

								return (
									<li className={isActiveLink(location, url) ? 'active' : ''} key={index}>
										<Link to={url}>
											{ label }
										</Link>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>
		</>
	)

}
